<template>
  <BeseDesignCombinationPoductPage :spanLevelList="spanLevelList" :resetTableOption="resetTableOption" :url="url" v-bind="permissionList"/>
</template>

<script>
import BeseDesignCombinationPoductPage from '../module/beseDesignCombinationPoductPage'
import { tableOption as resetTableOption, permissionList, spanLevelList } from './const'
export default {
  components: {
    BeseDesignCombinationPoductPage
  },
  data() {
    return {
      resetTableOption,
      permissionList,
      spanLevelList,
      url: '/externaladmin/productService/combinedProductPrototypeCollection/list',
    }
  }
}
</script>

<style>
</style>
