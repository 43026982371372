//定制组合产品管理
import { checkPermission } from '@/utils'

export const permissionList = {
  hasRelationDetail: checkPermission('externaladmin:combinedProductPrototypeCollection:detail') //关联详情
}

//合并的层级
export const spanLevelList = {
  level1: ['combinedInfo', 'createByName', 'createTime', 'done'],
  level2: ['combinedColorName']
}

export const tableOption = {
  column: [
    {
      label: '产品名称',
      prop: 'combinedChineseName',
      search: true,
      hide: true
    },
    {
      label: '组合定制产品信息',
      prop: 'combinedInfo',
      slot: true
    },

    {
      label: '颜色',
      prop: 'combinedColorName'
    },
    {
      label: '尺码',
      prop: 'combinedSizeName',
      minWidth: 100
    },
    {
      label: 'SKU',
      prop: 'combinationCustomSku',
      search: true
    },
    {
      label: '业务员',
      prop: 'createByName',
      search: true
    },
    {
      prop: 'create_time',
      search: true,
      searchSpan: 9,
      searchLabel: '时间',
      searchFormSlot: true,
      hide: true
    },
    {
      label: '时间',
      prop: 'createTime'
    }
  ]
}
